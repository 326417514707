@font-face {
    font-family: Metro Sans;
    src: url("../assets/fonts/font-regular.otf") format("opentype");
    font-weight: 400;
}
@font-face {
    font-family: Spoof Trial;
    src: url("../assets/fonts/font2.otf") format("opentype");
}
* {
    font-family: Metro Sans;
}
.spoof {
    font-family: Spoof Trial;
}
.container {
    width: 100%;
}
@media only screen and (min-width: 1024px) {
    .container {
        max-width: 960px;
    }
}
@media only screen and (min-width: 1216px) {
    .container {
        max-width: 1152px;
    }
}
@media only screen and (min-width: 1408px) {
    .container {
        max-width: 1344px;
    }
}

.w-full {
  width: 100vw !important;
}

input::placeholder,
textarea::placeholder,
select::placeholder {
    color: #667085;
}
input,
select,
textarea {
    outline: none !important;
}
.scBg {
    background-color: #fff !important;
}

.szh-accordion__item {
    width: 100%;
    box-shadow: 0px 12px 24px -4px #919eab1f;
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
    margin-bottom: 20px;
}
.szh-accordion__item-heading {
    width: 100%;
}
.szh-accordion__item-btn {
    width: 100%;
    outline: none;
}
.szh-accordion__item-panel {
    color: #5c6f7f;
    font-size: 16px;
    line-height: 30px;
    padding-top: 10px;
}
.szh-accordion__item-content {
    transition: height 0.3s cubic-bezier(0, 0, 0, 1);
}
.itemBtnExpanded .btnIcon1 {
    display: none;
}
.notItemBtnExpanded .btnIcon2 {
    display: none;
}
@media only screen and (max-width: 768px) {
    .szh-accordion__item-panel {
        line-height: 22.4px !important;
    }
}
