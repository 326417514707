.reactMarkDown {
    margin-block: 3rem 4rem;
    margin-top: 120px;
    margin-inline: auto;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
@media screen and (max-width: 768px) {
    .reactMarkDown {
        max-width: 90%;
    }
}

.reactMarkDown h2 {
    font-size: 1.2rem;
    font-weight: bolder;
}

.reactMarkDown p {
    font-size: 0.9rem;
    font-weight: 400;
    white-space: pre-wrap;
}

.reactMarkDown p > strong {
    font-size: 0.9rem;
    font-weight: bold;
}

.reactMarkDown ul,
.reactMarkDown ol {
    margin-bottom: 1em;
    list-style: lower-latin outside none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.reactMarkDown ul li,
.reactMarkDown ol li {
    margin-left: 1.4rem;
    font-size: 0.9rem;
    display: list-item;
    padding-left: 5px;
    text-transform: capitalize !important;
    text-align: -webkit-match-parent;
}
